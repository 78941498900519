import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["response"]

  submit() {
    Turbo.navigator.submitForm(this.element)
  }

  clear() {
    this.responseTarget.value = ""
    this.submit()
  }

}
