// app/javascript/controllers/response_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["answer"]

  choose(event) {
    const selected_answer = event.target;
    const indexOfSelected = Array.from(selected_answer.parentNode.children).indexOf(selected_answer);
    selected_answer.innerText = " "
    const loader = document.createElement("i")
    loader.classList.add("fa-solid", "fa-spinner", "fa-spin")
    selected_answer.appendChild(loader);

    this.toggleDisable(selected_answer)

    this.answerTargets.forEach((element, index) => {


      if (index !== indexOfSelected) {

        element.classList.remove('chosen');
        element.classList.add('disabled');
      }
    })

    const url = selected_answer.dataset.url
    const csrf_token = document.head.querySelector("meta[name=csrf-token]")?.content

    window
      .fetch(url, {
        method: "POST",
        headers: { "X-CSRF-Token" : csrf_token }
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Response status: ${response.status}`);
        }
        return response.text()
      })
      .then(html => Turbo.renderStreamMessage(html));


    }

    toggleDisable(element) {
      if (element.classList.contains('disabled')) {
        element.classList.replace('disabled', 'chosen')
      } else {
        element.classList.add('chosen')
      }
  }
}
